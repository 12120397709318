import undo_gif from ".././assets/undo.gif";
import makingfriends_png from ".././assets/makingfriends.png";
import whoopi_png from ".././assets/whoopi.png";
import popup_gif from ".././assets/popup.gif";
import bigtake_png from ".././assets/bigtake.png";
import hov_png from ".././assets/hov.png";
import group_chat_gif from ".././assets/group-chat.gif";
import iheart_png from ".././assets/iheart.png";
import aida_laugh_gif from ".././assets/aida-laugh.gif";
import afterlives_png from ".././assets/afterlives.png"
import aunties_gif from ".././assets/aunties.gif";

export const carouselData = [
{
    id: "undo",
    image: undo_gif,
    title: "Practice Thing",
    source: "UnionDocs",
    subtitle: "As a guest director-in-residence at the UnionDocs Center for Documentary Art, I shared some thoughts on artistic practice and working across disciplines.",
    url: "https://membership.uniondocs.org/programs/aaron-edwards-practice-thing",
},
{
    id: "makingfriends",
    image: makingfriends_png,
    title: "Making friends",
    source: "Slate",
    subtitle: "A lot of my work deals with culture, friendship, and the ways we develop a sense of self. I wrote this essay about building community after moving to a small town.",
    url: "https://slate.com/advice/2024/09/how-to-make-friends-adult-common-advice-moving.html",
},
{
    id: "bigtake",
    image: bigtake_png,
    title: "Big Take",
    source: "Bloomberg",
    subtitle:
      "I'm currently a part-time story editor for Bloomberg's daily narrative podcast that showcases in-depth, original reporting on one global story each day.",
    url: "https://podcasts.apple.com/us/podcast/influencers-fueled-zyns-rise-could-the-attention-backfire/id1578096201?i=1000685361449",
  },
  {
    id: "popup",
    image: popup_gif,
    title: "Pop-Up Magazine",
    source: "BAM, Lincoln Center",
    subtitle: "For several years, I produced, directed, and hosted Pop-Up Magazine, a touring 'live magazine' that brought stories to life on stage with an orchestra and visuals.",
    url: "https://www.mcdbooks.com/electric_eel/issue-026",
  },
  {
    id: "whoopi",
    image: whoopi_png,
    title: "Grams",
    source: "AudioFlux",
    subtitle: "An audio piece about magic mushrooms that I made as part of the inaugural cohort of Audio Flux. Featured in the Institute for Contemporary Art and the Tribeca Festival.",
    url: "https://tinkmedia.co/interviews/aaron-edwards",
  },
  {
    id: "unread",
    image: iheart_png,
    title: "Unread",
    source: "iHeart",
    subtitle:
      "I story-edited this New York Magazine Best Of 2021 podcast by Chris Stedman about friendship, loss, mental health, and the things we leave behind.",
    url: "https://podcasts.apple.com/us/podcast/unread/id1568341860",
  },
  {
    id: "group_chat",
    image: group_chat_gif,
    title: "Long live the group chat",
    source: "The Outline",
    subtitle:
      "As a founding editor of The Outline, I spearheaded special projects that melded design, text, and visuals (this works best on desktop). I also hosted a daily podcast.",
    url: "https://theoutline.com/post/2315/long-live-the-group-chat",
  },
  {
    id: "hov",
    image: hov_png,
    title: "The Book of Hov",
    source: "Roc Nation",
    subtitle: "I wrote and directed audio stories, narrated by Angie Martinez, for JAY-Z's tribute exhibition at The Brooklyn Public Library.",
    url: "https://www.thebookofhov.com/1-introduction",
  },
  {
    id: "laugh_aida",
    image: aida_laugh_gif,
    title: "How to laugh when nothing is funny",
    source: "Pop-Up Magazine",
    subtitle:
      "An at-home short about Black humor that I produced and directed, starring Aida Osman (HBO Max's Rap Sh!t).",
    url: "https://www.youtube.com/watch?v=4wGoFgu0leY",
  },
  {
    id: "afterlives",
    image: afterlives_png,
    title: "Afterlives",
    source: "iHeart",
    subtitle:
      "This series I helped develop and story-edit unpacks the systems of power that failed Layleen Polanco, an Afro-Latina trans woman who died on Rikers Island.",
    url: "https://podcasts.apple.com/us/podcast/afterlives-the-layleen-polanco-story/id1715577597",
  },
  {
    id: "aunties",
    image: aunties_gif,
    title: "The enduring power of aunties",
    source: "The Outline",
    subtitle:
      "A project I created that combined prose, poetry, and illustration to reflect on the role of aunts in our lives.",
    url: "https://theoutline.com/post/2547/the-enduring-power-of-aunties",
  },
];
